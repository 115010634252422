import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill'; // Import the ReactQuill component
import 'react-quill/dist/quill.snow.css'; // Import the styles

const TextEditor = ({ setContent, initialContent }) => {
  const [editorContent, setEditorContent] = useState(initialContent);
  const quillRef = useRef(null);

  useEffect(() => {
    setEditorContent(initialContent);
  }, [initialContent]);

  const handleChange = (content) => {
    setEditorContent(content);
    setContent(content);
  };

  return (
    <ReactQuill
      ref={quillRef}
      value={editorContent}
      onChange={handleChange}
      modules={TextEditor.modules}
      formats={TextEditor.formats}
      bounds={'.app'}
      theme="snow"
    />
  );
};

// Function to handle image uploads
async function uploadImage(file) {
  const formData = new FormData();
  formData.append('image', file);
  try {
    const response = await fetch('/api/upload', {
      method: 'POST',
      body: formData,
    });
    if (!response.ok) {
      throw new Error('Network response was not ok.');
    }
    const data = await response.json();
    return data.url; // Assuming the server sends back the URL of the image
  } catch (error) {
    console.error('Upload failed:', error);
    return ''; // Return an empty string or handle the error as needed
  }
}

// Function to handle image uploads
function imageHandler() {
  const input = document.createElement('input');
  input.setAttribute('type', 'file');
  input.setAttribute('accept', 'image/*');
  input.click();

  input.onchange = async () => {
    const file = input.files[0];
    if (file) {
      const url = await uploadImage(file);
      const range = this.quill.getSelection();
      if (url && range) {
        this.quill.insertEmbed(range.index, 'image', url);
      }
    }
  };
}

// Specify modules to use Quill modules including the toolbar
TextEditor.modules = {
  toolbar: {
    container: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline'],
      ['image'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],
    ],
    handlers: {
      'image': imageHandler
    }
  },
  clipboard: {
    // Extend clipboard module
    matchVisual: false,
  },
};

// Specify formats to allow Quill formats
TextEditor.formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video'
];

export default TextEditor;
