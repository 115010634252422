import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser as faUserSolid, faBars } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';


function SiteHeader({ menuActive, toggleSidebar }) {
  const { loginWithRedirect, logout, isAuthenticated, user } = useAuth0();

  const [userMenuOpen, setUserMenuOpen] = useState(false);

  const toggleUserMenu = () => {
    setUserMenuOpen(!userMenuOpen);
  };

  //console.log('isAuthenticated: ', isAuthenticated);
  return (
    <header className="site-header">
      <div className="header-content">
      <div className={`hamburger-menu ${menuActive ? 'change' : ''}`} onClick={() => isAuthenticated ? toggleSidebar() : loginWithRedirect()}>
          <span></span>
          <span></span>
        </div>
        <img src="/favicon.png" alt="Lantern AI Favicon" className="header-favicon"/>
        <span className="header-title">Lantern AI</span>
        <span className="header-icon right" onClick={toggleUserMenu}>
          {/* Conditionally render the welcome message with styles */}
          {isAuthenticated && (
            <span className="welcome-text" style={{ fontSize: '.8em',  color: 'lightblue', fontFamily: "'Roboto', sans-serif", marginRight: '10px' }}>
              Welcome, {user.name}
            </span>
          )}
          <FontAwesomeIcon icon={faUserSolid} style={{ color: "lightgrey" }} />
        </span>
        {userMenuOpen && (
  <div className="menu" onMouseLeave={toggleUserMenu}>
     <ul>
      {isAuthenticated ? (
        <>
          {/* <li>
            <Link to="/profile" style={{ textDecoration: 'none', color: 'inherit' }}>
              Profile
            </Link>
          </li> */}
          <li onClick={() => logout({ returnTo: window.location.origin })}>Log out</li>
          {/* Other user-specific menu items */}
        </>
      ) : (
        <li onClick={loginWithRedirect}>Log in</li>
      )}
      {/* Add the Privacy Policy link */}
      <li>
        <Link to="/privacy-policy" style={{ textDecoration: 'none', color: 'inherit' }}>
          Privacy Policy
        </Link>
      </li>
      <li>
        <Link to="/terms-of-service" style={{ textDecoration: 'none', color: 'inherit' }}>
          Terms of Service
        </Link>
      </li>
      <li>
        <a className="link-nodec" href="mailto:support@journeywithlantern.com?subject=Support Request&body=Hi Support Team,">
          Contact Support
        </a>
      </li>
      {/* You can add other general links or settings here */}
    </ul>
  </div>
)}
      </div>
    </header>
  );
}

export default SiteHeader;
