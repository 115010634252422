import React, { useState, useEffect, useRef, useCallback } from 'react';
import DOMPurify from 'dompurify';
// import { useAuth0 } from '@auth0/auth0-react';
import { Box, Typography, Tabs, Tab } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { blue } from '@mui/material/colors';
import TextEditor from './TextEditor';


const DeleteConfirmationModal = ({ onDelete }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleDelete = () => {
    onDelete();
    handleClose();
  };

  return (
    <>
      <button type="button" className='deleteIcon' onClick={handleShow}>
        <FontAwesomeIcon icon={faTrashAlt} />
      </button>

      {show && (
        <div className="custom-modal">
          <div className="custom-modal-content">
            <span className="close" onClick={handleClose}>&times;</span>
            <h3>Delete this session?</h3>
            <p>The entry and all corresponding guidance interactions will be permanently deleted. This action cannot be undone.</p>
            <div className="custom-modal-footer">
              <button onClick={handleClose}>Cancel</button>
              <button onClick={handleDelete}>Delete</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};



/**
 * A React component for the journal entry form.
 */

const EntryForm = ({ searchQuery, disabled, showFeedback, setShowFeedback, activeTab, setActiveTab, handleSubmit, selectedEntry, selectedEntryID, guide1, guide2, guide3, guide1Waiting, guide2Waiting, guide3Waiting, tab1disabled, tab2disabled, tab3disabled, isProcessing, isCreatingEntry, setSelectedEntry, handleDeleteEntry, handleSaveEntry, onAdd, prevState, entryFormState, setEntryFormState, lastSavedContent, setLastSavedContent, lastSaved, setLastSaved, saveStatus, setSaveStatus }) => {

  const [content, setContent] = useState(''); // Initialize with empty string or any initial content
  const [uploadedImage, setUploadedImage] = useState(null);
const [displayedText, setDisplayedText] = useState('');
  const typingStatus = useRef({ index: 0, cancelTyping: false });
  const [typingCompleted, setTypingCompleted] = useState(false);
const waitingHTML = "<h1>Please Wait</h1>@wait<p>Take a deep breath and appreciate this pause. @wait@waitAs you wait for your entry to be processed, take a moment to bask in the effort you’ve invested today. @wait@waitIt's a short interval, but it's enough to remind you that you're making progress on this incredible journey called life.<br><br>@waitRemember, this isn't just a simple task you're checking off; it's a profound act of self-exploration. @waitEvery word you've written is a piece of the puzzle that makes up you@wait — complex, beautiful, ever-changing.@wait Collectively your reflections solidify to form the road that takes you to a deeper understanding of yourself.@wait<br><br>Just as a lantern's glow helps you navigate through darkness, your words, captured here, illuminate the pathways of your inner world.</p>";
  const waitingHTMLPlain = waitingHTML.replace(/@wait/g, "");
  
  const sanitizeHTML = (html) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  
  useEffect(() => {
    if (content !== lastSavedContent) {
      setSaveStatus("Unsaved changes");
      const minutesSinceLastSave = Math.floor((Date.now() - lastSaved) / 60000);
      if (minutesSinceLastSave > 0) {
        setSaveStatus(`Last saved ${minutesSinceLastSave} min${minutesSinceLastSave > 1 ? 's' : ''} ago`);
      }
    }
  }, [content, lastSavedContent]);

  useEffect(() => {

    if (content === lastSavedContent) {
      setSaveStatus("All changes saved");
      setLastSaved(Date.now());
    }
  }, [content, lastSavedContent]);
  
  useEffect(() => {
    const statusUpdateInterval = setInterval(() => {
if (content !== lastSavedContent) {
          const minutesSinceLastSave = Math.floor((Date.now() - lastSaved) / 60000);
          if (minutesSinceLastSave > 0) {
            setSaveStatus(`Last saved ${minutesSinceLastSave} min${minutesSinceLastSave > 1 ? 's' : ''} ago`);
          }
        }
      
    }, 10000);  // interval for updating the save status
  
    return () => clearInterval(statusUpdateInterval);
  }, [lastSavedContent, lastSaved]);  // Removed quillRef.current.root?.innerHTML from the dependencies
  

  const [charCount, setCharCount] = useState(0);
  const MAX_CHARS = 5000;


const helpButtonVisible = !content.trim(); 

const handleSave = useCallback(() => {
console.log("SelectedEntryID:", selectedEntryID);
      console.log("Editor HTML content:", content);  // Since you use 'content' here, it should be a dependency
      
      handleSaveEntry(content);
      setLastSavedContent(content);
      setLastSaved(Date.now());
      setSaveStatus("All changes saved");
  
}, [selectedEntryID, handleSaveEntry, content]); // Include 'content' in dependencies


const handleFormSubmit = async (e) => {
  e.preventDefault();
  if(!guide1){
  handleSave(); // Ensures the entry content is saved first.
  }
  // Prepare form data to include in the submission
  const formData = prepareAndProcessForm(); 
  handleSubmit(formData); // Pass formData to handleSubmit in App.js
};
const prepareAndProcessForm = () => {
  let formData = {};
  const form = document.getElementById('gptForm');
  if (form) {
    Array.from(form.elements).forEach(element => {
      const questionId = element.id;
      const questionText = element.labels.length > 0 ? element.labels[0].innerText : '';
      const questionNumber = questionId.replace(/\D/g, '');
      console.log("Processing element:", element); // Log added
      if (element.tagName === 'TEXTAREA' && !element.classList.contains('ql-')) {
        formData[questionNumber] = { questionText, answer: element.value };
      } else if (element.classList.contains('saveButton')) {
        const editorContent = content;
        formData[questionNumber] = { questionText: "Journal Entry", answer: editorContent };
      }
    });
  } else {
    console.error("Form not found");
  }
  console.log("Form data prepared:", formData); // Log added
  return formData;
};


// function getTextFromEditor(editorId) {
//   var quill = new Quill(`#${editorId}`);
//   return quill.root.innerHTML;
// }


const handleImageUpload = async (event) => {
  const file = event.target.files[0];
  if (!file) return;

  // Update the UI to show the uploading state
  // ...

  // Create a FormData object and append the file
  const formData = new FormData();
  formData.append('journalImage', file);

  try {
    const response = await fetch('/api/upload', {
      method: 'POST',
      body: formData, // Send the FormData object to the backend
      // No headers for 'Content-Type' needed; 'fetch' will set it to 'multipart/form-data'
    });

    const data = await response.json();

    // Handle response
    if (data.imageUrl) {
      setUploadedImage(data.imageUrl);
      // Now uploadedImage state contains the URL to the uploaded image
    }
  } catch (error) {
    console.error('Upload failed:', error);
    // Handle upload failure
  }
};



 // Autosave content
 useEffect(() => {
  const handleAutosave = () => {
    if (Date.now() - lastSaved > 60000 && !guide1 && activeTab === 0) { // 2 minutes
      console.log("Autosaving content...");
      handleSaveEntry(content);
      setLastSaved(Date.now());
      setSaveStatus("All changes saved");
    }
  };

  const intervalId = setInterval(handleAutosave, 5000); // check every 5 seconds

  return () => clearInterval(intervalId);
}, [content, lastSaved, handleSaveEntry]);


useEffect(() => {
  // Check if the activeTab is tab0 and guide1 is not set
  if (activeTab === 0 && !guide1) {
    // Wait for 10 seconds after the component with Guide1 is rendered
    const timeoutId = setTimeout(() => {
      // Target element ID where you want to show the typing effect
      const targetElementId = 'Qi'; // Change this to your actual element's ID
      
      // Text you want to simulate typing
      const placeholderText = "[Begin typing when you're ready]@wait\nWelcome to your space of clarity.@wait Allow yourself to take a moment to pause.@wait Breathe in the stillness@wait, breathe out the noise.@wait\n\nThis interlude invites you to practice mindfulness by exploring your inner world.@wait@wait\n\nInvite your thoughts and feelings to flow freely.@wait@wait Write them down here@wait...@waitAllow them to breathe.";
      // Execute your typing simulation function
      simulateTypingPlaceholder(placeholderText, 35, 2000, 0, targetElementId)
        .then(() => {
          //console.log('Typing simulation completed.');
        })
        .catch((error) => {
          console.error('Typing simulation failed:', error);
        });
    }, 5000);
    
    // Clear the timeout if the component unmounts
    return () => clearTimeout(timeoutId);
  }
}, [activeTab, guide1]); // Only re-run the effect if activeTab or guide1 changes


//PLEASE WAIT: fills waiting text immediately on lost focus 

useEffect(() => {
  const handleVisibilityChange = () => {
    if (document.visibilityState === 'hidden' ) { //&& !typingCompleted
      
      typingStatus.current.cancelTyping = true;
      
      if (guide1Waiting || guide2Waiting || guide3Waiting) {
        console.log("innerHTML: HandleVisibilityChange");
        document.getElementById("waitingElement").innerHTML = waitingHTMLPlain;
      }
      
    }
  };

  document.addEventListener('visibilitychange', handleVisibilityChange);

  return () => {
    document.removeEventListener('visibilitychange', handleVisibilityChange);
  };
}, [activeTab, guide1Waiting, guide2Waiting, guide3Waiting]); //, typingCompleted

useEffect(() => {
  const helpButton = document.getElementById('helpButton');
  if (helpButton) {
    helpButton.classList.add('visible');
  }
}, []);


  const [isFocused, setIsFocused] = useState(false);

  const handleTextareaFocus = () => {
    setIsFocused(true);
    document.querySelector('.sidebar').classList.add('dim');
  };

  const handleTextareaBlur = () => {
      document.querySelector('.sidebar').classList.remove('dim');    
  };

  const handleMouseLeaveMainContent = () => {
    if (isFocused) return; // Keep the sidebar dimmed if textarea is still focused
    document.querySelector('.sidebar').classList.remove('dim');
  };

  function HighlightSearchQuery({ text, query }) {
    if (!query) {
      return <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }} />;
    }
  
    const sanitizedText = DOMPurify.sanitize(text);
    const parts = sanitizedText.split(new RegExp(`(${query})`, 'gi')); // Split text into parts
    return (
      <div>
        {parts.map((part, index) =>
          part.toLowerCase() === query.toLowerCase() ? (
            <span key={index} style={{ backgroundColor: 'yellow' }}>{part}</span> // Highlight style
          ) : (
            <span key={index} dangerouslySetInnerHTML={{ __html: part }} />
          )
        )}
      </div>
    );
  }
  


/**
 * @function
 * @name simulateTypingHTML
 * Simulates typing HTML content into a target element.
 */

function simulateTypingHTML(htmlText, typingSpeed, waitTime, breakTime, targetElementId) {
  console.log('start typing sim');
  return new Promise((resolve) => {
    let tempString = '';
    let index = 0;
    let insideTag = false;
    let wait = false;
    const waitKeyword = '@wait';
    const targetElement = document.getElementById(targetElementId);
    typingStatus.current.cancelTyping = false;

    function typeChar() {
      // If typing is canceled or target element doesn't exist, clear the text and resolve the promise
      if (typingStatus.current.cancelTyping || !targetElement) {
        console.log("innerHTML: Clears waiting text due to cancellation");
        targetElement.innerHTML = ''; // Just clear the waiting text, don't populate full text
        resolve();
        return;
      }

      if (index < htmlText.length) {
        // Check if we hit the @wait keyword and should pause typing
        if (htmlText.substring(index, index + waitKeyword.length) === waitKeyword) {
          wait = true;
          index += waitKeyword.length;
        }

        if (wait) {
          // Pause typing for a set wait time
          setTimeout(() => {
            wait = false;
            requestAnimationFrame(typeChar); // Resume typing after the wait time
          }, waitTime);
          return;
        }

        const char = htmlText[index];
        tempString += char;

        // Handle tags by setting insideTag flag when a tag starts or ends
        if (char === '<') {
          insideTag = true;
        } else if (char === '>') {
          insideTag = false;
        }

        if (!insideTag) {
          // Only update the innerHTML if we're not inside an HTML tag
          targetElement.innerHTML = tempString;
          console.log("innerHTML: Updated with typed content");
        }

        index++; // Move to the next character
        setTimeout(typeChar, typingSpeed); // Control typing speed
      } else {
        // Typing finished
        resolve();
      }
    }

    // Start typing simulation after breakTime
    setTimeout(() => {
      if (!targetElement) {
        resolve();
        return;
      }
      targetElement.innerHTML = ''; // Clear any previous content before starting the typing simulation
      typeChar();
    }, breakTime);
  });
}



/**
 * @function
 * @name simulateTypingPlaceholder
 * Simulates typing placeholder text into a target element.
 */

function simulateTypingPlaceholder(text, typingSpeed, waitTime, breakTime, targetElementId) {
  return new Promise((resolve) => {
    let tempString = '';
    let index = 0;
    let wait = false;
    const waitKeyword = '@wait';
    const targetElement = document.getElementById(targetElementId);

    // Ensure the target element is an input or textarea
    if (!targetElement || (targetElement.tagName !== 'INPUT' && targetElement.tagName !== 'TEXTAREA')) {
      console.error('Target element is not an input or textarea');
      resolve();
      return;
    }

    function typeChar() {
      if (index < text.length) {
        if (text.substring(index, index + waitKeyword.length) === waitKeyword) {
          wait = true;
          index += waitKeyword.length;
        }

        if (wait) {
          setTimeout(() => {
            wait = false;
            requestAnimationFrame(typeChar);
          }, waitTime);
          return;
        }

        const char = text[index];
        tempString += char;

        // Update the placeholder attribute instead of innerHTML
        targetElement.setAttribute('placeholder', tempString);

        index++;
        setTimeout(typeChar, typingSpeed); // Use typingSpeed to control delay between each character
      } else {
        resolve();
      }
    }

    setTimeout(() => {
      targetElement.setAttribute('placeholder', ''); // Clear placeholder before starting
      typeChar();
    }, breakTime);
  });
}


//Waiting typing sim trigger

useEffect(() => {
  // If the form is processing, start the typing simulation.
  if (isProcessing && !typingCompleted) {
    const typingTimeout = setTimeout(() => {
      simulateTypingHTML(waitingHTML, 20, 2000, 0, "waitingElement")
        .then(() => {
          setTypingCompleted(true); // Mark typing as complete.
        });
    }, 1000); // 3-second delay before typing starts

    // Clear the timeout if the component is unmounted or processing stops
    return () => clearTimeout(typingTimeout);

  } else if (!isProcessing) {
    // If processing is done but typing has not completed, cancel the typing.
    typingStatus.current.cancelTyping = true;
    
    const waitingElement = document.getElementById("waitingElement");
    if (waitingElement) {
     
      waitingElement.innerHTML = ""; // Clear the waiting text immediately.
      console.log("innerHTML: clear waiting text");
    }
    setTimeout(() => {
      setShowFeedback(true);  // Trigger fade-in after waiting text is cleared
      setDisplayedText(entryFormState.gptFeedback);
    }, 1000);  // Delay fade-in slightly
    
    // Reset typing simulation state for the next interaction
    setTypingCompleted(false);
  }
}, [entryFormState.gptFeedback, isProcessing, setShowFeedback, typingCompleted]);

// 9.4.24 OG commented out to test new version above
// useEffect(() => {
//   // If the form is processing, start the typing simulation.
//   if (isProcessing) { // && !typingCompleted
//     // Start typing simulation.
//     setTimeout(() => {
      
    
//     simulateTypingHTML(waitingHTML, 20, 2000, 0, "waitingElement").then(() => {
//       setTypingCompleted(true); // Mark typing as complete.
//     });

//   }, 3000);

//   } else if (isProcessing === false ) { //&& guide1Waiting || guide2Waiting || guide3Waiting
//     // If processing is done but typing has not completed, cancel the typing.
//     typingStatus.current.cancelTyping = true;
//     console.log("innerHTML: clear waiting text");

//     const waitingElement = document.getElementById("waitingElement");
//     if (waitingElement) {
//       waitingElement.innerHTML = ""; // Clear the waiting text immediately.
//     }
    
//     setDisplayedText(entryFormState.gptFeedback);
//     setShowFeedback(true);
//     setTypingCompleted(false); // Reset for the next use.
//   }
// }, [setShowFeedback, isProcessing,  entryFormState.gptFeedback, guide1Waiting, guide2Waiting, guide3Waiting]);//typingCompleted,

  
  // if (!isAuthenticated) {
  //   // If the user is not authenticated, show a login button
  //   return (
  //     <div className="entry-form">
  //       <button onClick={() => loginWithRedirect()}>Log In to Edit or Create Entries</button>
  //     </div>
  //   );
  // }
return (
  <>
<Tabs value={activeTab} onChange={(event, newValue) => setActiveTab(newValue)}>
  <Tab label="Journal Entry" className="custom-tab" disabled = {disabled} />
  <Tab label="Guidance - 1" className="custom-tab" disabled = {tab1disabled}/>
  <Tab label="Guidance - 2" className="custom-tab" disabled = {tab2disabled}/>
  <Tab label="Guidance - 3" className="custom-tab" disabled = {tab3disabled}/>
</Tabs>


      {/* } */}
      {/* Journal Entry Tab */}
      {activeTab === 0 && (
  !selectedEntry ? (
    <Typography variant="h6" style={{ textAlign: 'center', color: 'blue', marginTop: '20px' }}>
      <br></br>
      <h1>Ready to begin your journey?</h1> 
      <p>Click the 'New Entry' button in the sidebar</p>
    </Typography>
  ) : !guide1 ? (
    <Box>
      <Typography component="div">
      

      <form id="gptForm" onSubmit={handleFormSubmit}>
  <div className="form-group">
    <label htmlFor='Qi' className="qi-label">What's on your mind?</label>

    <button type="button" id="helpButton" className={`helpButton ${helpButtonVisible ? 'visible' : ''}`}>Help me get started ➢</button>
  </div>

  <TextEditor
  key={selectedEntryID}
   setContent={setContent}
   initialContent={entryFormState.richTextContent}
/>


{/* <div className='charsUsed'>
      {charCount}/{MAX_CHARS} characters
    </div>   */}



  <div className="save-entry-container">
    <button type="button" className='saveButton' onClick={handleSave}>Save Entry</button>
    <span>{saveStatus}</span>



  {  /* <input
  type="file"
  accept="image/*"
  onChange={handleImageUpload}

/> */}

      <DeleteConfirmationModal onDelete={handleDeleteEntry} />
  </div>
  <button type="submit" className='guiButton'>Submit for Guidance</button>
</form>


      </Typography>
    </Box>
  ) : (
<div>
<form id="gptForm">
        <h3>{selectedEntry.title}</h3>
        <div id="content">
          {selectedEntry && selectedEntry.content
            ? selectedEntry.content.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  <div>
                    <HighlightSearchQuery text={line} query={searchQuery} />
                  </div>
                  <br />
                </React.Fragment>
              ))
            : ''}
        </div>
        <DeleteConfirmationModal onDelete={handleDeleteEntry} />
    </form>
</div>
  )
)}

      {/* Guide1 Tab */}
      {activeTab === 1 && (
        <Box>
  <Typography>
    <div>
    <div id="waitingElement"></div>

    {/* <div class="hero-image">
  <img src="guidance_hero_fpo.png" alt="Journey of Self-Discovery and Personal Growth"/>
</div> */}

    <form id="gptForm" onSubmit={handleFormSubmit}>

      
      <div className={`fade-in ${showFeedback ? 'show' : ''}`} dangerouslySetInnerHTML={{ __html: guide1 }} />
      <div className={`fade-in ${showFeedback ? 'show' : ''}`}>
      {/* <button type="submit" className='guiButton'>Submit</button> */}
      </div>
       {/* {activeTab === 1 && guide1 ? (<button className='guiButton' onClick={handleSubmit}>Submit</button>)} */}
       </form>
    </div>
  </Typography>
        </Box>
      )}

      {/* Guide2 Tab */}
      {activeTab === 2 && (
         <Box>
         <Typography>
           <div>
           <div id="waitingElement"></div>
           <form id="gptForm" onSubmit={handleFormSubmit}>
       
             
             <div className={`fade-in ${showFeedback ? 'show' : ''}`} dangerouslySetInnerHTML={{ __html: guide2 }} />
             <div className={`fade-in ${showFeedback ? 'show' : ''}`}>
             {/* <button type="submit" className='guiButton'>Submit</button> */}
             </div>
              {/* {activeTab === 1 && guide1 ? (<button className='guiButton' onClick={handleSubmit}>Submit</button>)} */}
              </form>
           </div>
         </Typography>
               </Box>
      )}

      {/* Guide3 Tab */}
      {activeTab === 3 && (
        <Box>
        <Typography>
          <div>
          <div id="waitingElement"></div>
          <form id="gptForm" onSubmit={handleFormSubmit}>
            
            <div className={`fade-in ${showFeedback ? 'show' : ''}`} dangerouslySetInnerHTML={{ __html: guide3 }} />
            <div className={`fade-in ${showFeedback ? 'show' : ''}`}>
            {/* <button type="submit" className='guiButton'>Submit</button> */}
            </div>
             {/* {activeTab === 1 && guide1 ? (<button className='guiButton' onClick={handleSubmit}>Submit</button>)} */}
             </form>
          </div>
        </Typography>
              </Box>
      )}

    </>
  );
};

export default EntryForm;