import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate instead of useHistory

const TermsOfService = () => {
  // const [accepted, setAccepted] = useState(false);
  let navigate = useNavigate(); // Initialize useNavigate hook

  // Function to navigate to the previous page in history
  const handleGoBack = () => {
    navigate(-1); // Navigate to the previous page in history
  };

  
//   const handleSubmit = () => {
//     if (accepted) {
//       // Implement actions upon acceptance, e.g., storing the acceptance, navigating away, etc.
//     } else {
//       alert('Please accept the Terms of Service to proceed.');
//     }
//   };


  return (
    
    <div className="terms-of-service-container">
                <div className='back-button-container'>
          <button onClick={handleGoBack} className="back-button">&#x2190;</button> {/* Thick left pointing arrow */}
        </div>
  <div className='h1-container'> <h1>Terms of Service for Lantern AI</h1></div>
  <p>Welcome to Lantern AI. These terms of service ("Terms") govern your use of the Lantern AI app and services ("Service"). By accessing or using the Service, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use the Service.</p>

  <section>
    <h2>Acceptance of Terms</h2>
    <ul>
      <li>The Service is offered subject to acceptance of all the terms and conditions contained in these Terms and all other operating rules, policies, and procedures.</li>
      <li>We reserve the right to update the Terms at any time. Your continued use of the Service after any such changes constitutes your acceptance of the new Terms.</li>
    </ul>
  </section>

  <section>
    <h2>Usage Guidelines</h2>
    <ul>
      <li>You agree to use the Service in compliance with all applicable laws and regulations and not to use the Service for any unlawful purposes.</li>
      <li>You are responsible for all your activity in connection with the Service.</li>
    </ul>
  </section>

  <section>
    <h2>Sharing and Attribution</h2>
    <ul>
      <li>While users maintain ownership of their content, we appreciate attribution to Lantern AI when content generated or inspired by our platform is shared publicly. This helps support our community and allows others to learn about the tools and resources Lantern AI provides.</li>
    </ul>
  </section>

  <section>
    <h2>Intellectual Property</h2>
    <ul>
      <li>All content included on the Service, such as text, graphics, logos, and software, is the property of Lantern AI or its licensors and is protected by international copyright laws.</li>
      <li>Lantern AI grants you a non-exclusive, non-transferable, revocable license to use the Service strictly in accordance with these Terms.</li>
    </ul>
  </section>

  <section>
    <h2>User Content Ownership & Control</h2>
    <ul>
      <li>Users retain all rights and ownership of their content. Lantern AI does not claim any ownership rights over user-generated content. The content created by users is for their personal use and remains entirely under their control.</li>
    </ul>
  </section>

  <section>
    <h2>Disclaimer and Liability Limitation</h2>
    <ul>
      <li>The Service is provided on an "as is" and "as available" basis. Lantern AI does not warrant that the Service will be uninterrupted or error-free.</li>
      <li>Lantern AI shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of the Service.</li>
    </ul>
  </section>

  <section>
    <h2>Amendment of Terms</h2>
    <ul>
      <li>We may, at our sole discretion, modify or revise these Terms at any time. You are expected to check this page periodically to take notice of any changes we made.</li>
    </ul>
  </section>

  <section>
    <h2>Governing Law</h2>
    <ul>
      <li>These Terms shall be governed by and construed in accordance with the laws of the jurisdiction in which Lantern AI operates, without regard to its conflict of law provisions.</li>
    </ul>
  </section>
  <br></br>

  <p>By using Lantern AI, you acknowledge and agree to the terms outlined in this Terms of Service.</p>

        {/* <input
          type="checkbox"
          id="acceptTOS"
          checked={accepted}
          onChange={handleAcceptance}
        />
        <label htmlFor="acceptTOS">I accept the terms of the Terms of Service.</label>

      <button
        onClick={handleSubmit}
        disabled={!accepted}
        className={accepted ? 'button-enabled' : 'button-disabled'}
      >
        Submit
      </button> */}
    </div>
  );
};

export default TermsOfService;
