
import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { getAllEntries } from './services/api';
import { addEntry, interactWithEntry, createSummaryForEntry, createLifetimeSummary, saveEntry } from './services/api';
import SiteHeader from './components/SiteHeader';
import EntryForm from './components/EntryForm';
import EntryList from './components/EntryList';
import LandingPage from './components/LandingPage'; 
import './App.css';
import UserTray from './components/UserTray';

const API_URL = process.env.REACT_APP_API_URL;

function App() {
  const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();
  const [allEntries, setAllEntries] = useState([]);  // New state to hold all entries
  const [entries, setEntries] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [selectedEntryId, setSelectedEntryId] = useState(null); // State for the ID of the selected entry
  const [userId, setUserId] = useState(null);
  const [guide1, setGuide1] = useState(null);
  const [guide2, setGuide2] = useState(null);
  const [guide3, setGuide3] = useState(null);
  const [guide1Waiting, setGuide1Waiting] = useState(null);
  const [guide2Waiting, setGuide2Waiting] = useState(null);
  const [guide3Waiting, setGuide3Waiting] = useState(null);
  const [isProcessing, setIsProcessing] = useState(null);
  const [isEntrySaved, setIsEntrySaved] = useState(false);
  const tab1disabled = !guide1Waiting && !guide1;
  const tab2disabled = !guide2Waiting && !guide2;
  const tab3disabled = !guide3Waiting && !guide3;

  const [activeTab, setActiveTab] = useState(0);
  // const [gptFeedback, setGptFeedback] = useState(null);'

  ///OLD Version
          // const [entryFormState, setEntryFormState] = useState({
          //   text: '',
          //   currentHTML: '',
          //   gptFeedback: null,
          // });
  ///NEW version
  const [entryFormState, setEntryFormState] = useState({
    richTextContent: '', // initially empty, updated by Quill
    gptFeedback: null,
  });
  
  // Now that entryFormState is initialized, you can destructure it
  const [isCreatingEntry, setIsCreatingEntry] = useState(false);
  const [showFeedback, setShowFeedback] = useState(true);

  //sidebar toggle
  const [sidebarVisible, setSidebarVisible] = useState(false);

  //save states
  const [lastSavedContent, setLastSavedContent] = useState("");
  const [lastSaved, setLastSaved] = useState(Date.now());
  const [saveStatus, setSaveStatus] = useState("All changes saved");
  
   // Function to toggle the sidebar
   const [menuActive, setMenuActive] = useState(false);

  const toggleSidebar = () => {
    const shouldSidebarBeVisible = !sidebarVisible;
    setSidebarVisible(shouldSidebarBeVisible);
    setMenuActive(shouldSidebarBeVisible); // Toggle menu active state
  };

  // Function to close the sidebar
  const closeSidebar = () => {
    setSidebarVisible(false);
 };

  const fetchEntryById = async (entryId) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${API_URL}/entries/${entryId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return await response.json();
    } catch (error) {
      console.error("Failed to fetch entry:", error);
    }
  };


    // fetchEntries function
  const fetchEntries = async (query) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${API_URL}/entries/search?query=${encodeURIComponent(query)}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const fetchedEntries = await response.json();
      return fetchedEntries.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    } catch (error) {
      console.error("Failed to fetch entries:", error);
      return []; // Return an empty array on failure
    }
  };

  useEffect(() => {
    if (isAuthenticated && user) {
      // Assuming the user's sub (subject identifier) is used as the userId
      setUserId(user.sub);
      console.log("App.js userId set: ", userId);
      console.log("user.sub: ", user.sub);
    }
  }, [user, isAuthenticated]);

  useEffect(() => {
    const loadAllEntries = async () => {
      try {
        const token = await getAccessTokenSilently();
        const fetchedEntries = await getAllEntries(token);
        setAllEntries(fetchedEntries);  // Save all entries
        setEntries(fetchedEntries);  // Initially display all entries
      } catch (error) {
        console.error('Failed to load entries:', error);
      }
    };
  
    if (isAuthenticated) {
      loadAllEntries();
    }
  }, [isAuthenticated, getAccessTokenSilently]);
  

  // // Effect for handling changes to searchQuery
  // useEffect(() => {
  //   const loadEntries = async () => {
  //     const sortedEntries = await fetchEntries(searchQuery);
  //     setEntries(sortedEntries);
  //   };

  //   loadEntries();
  // }, [searchQuery]); // This effect runs whenever searchQuery changes
  
  // Handle selecting an entry


useEffect(() => {
  const fetchAndSelectEntry = async () => {
    try {
      const token = await getAccessTokenSilently(); // Get the token
      const sortedEntries = await fetchEntries(searchQuery); // Pass the token to fetchEntries
      //console.log('Entries fetched. sorteEntries.length: ', sortedEntries.length);
      setEntries(sortedEntries);
      if (sortedEntries.length > 0) {
        await handleEntrySelect(sortedEntries[0]._id); // If there are entries, handle selection
      } else {
        await handleNewEntry(); // If there are no sorted entries, create a new entry
      }

    } catch (error) {
      console.error('Error fetching and selecting entry:', error);
    }
  };

  if (isAuthenticated) {
    fetchAndSelectEntry();
  }
}, [isAuthenticated, getAccessTokenSilently]);


const handleDeleteEntry = async () => {
    try {
      const id = selectedEntryId;
      const token = await getAccessTokenSilently();
  
      // Find the index of the current entry in the sorted list
      const currentEntryIndex = entries.findIndex(entry => entry._id === id);
        // Check if entryOrId is just an ID (assuming IDs are strings and entry objects are not)

      // Delete the entry
      const response = await fetch(`${API_URL}/entries/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      try {
        const token = await getAccessTokenSilently();
        const sortedEntries = await fetchEntries(searchQuery);
        setEntries(sortedEntries);
        // Determine the next entry to select
        let nextEntryIndex = currentEntryIndex;
        if (nextEntryIndex >= sortedEntries.length) {
          nextEntryIndex = sortedEntries.length - 1;
        }
    
        // Update the state to select the next entry
        if (sortedEntries.length > 0) {
          handleEntrySelect(sortedEntries[nextEntryIndex]._id);
        }
      } catch (error) {
        console.error('Failed to process after delete:', error);
      }
  
    } catch (error) {
      console.error("Failed to delete entry:", error);
    }
  };

  const handleNewEntry = async () => {
    setIsCreatingEntry(true);
    setEntryFormState({
      richTextContent: '', // initially empty, updated by Quill
      gptFeedback: null,
    });
    setGuide1(null);
    setGuide2(null);
    setGuide3(null);
    setActiveTab(0);
    
    try {
      const token = await getAccessTokenSilently();
      const title = new Date().toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
      const content = ""; // Start with empty content for a new entry.
  
      // Attempt to add the entry via your API, passing the token
      const responseEntry = await addEntry(title, content, token);
      
      // Assuming your addEntry response has the necessary ID property
      //const newEntryId = responseEntry.entry._id; // or whatever property holds the new ID
              // Update the state to select the next entry

                handleEntrySelect(responseEntry.entry._id);

      //console.log('newEntryId: ', newEntryId);
  
      // Fetch entries to get the updated list
      const sortedEntries = await fetchEntries(searchQuery);
      setEntries(sortedEntries);
      // Now select the new entry
      setSelectedEntry(responseEntry.entry);
      setSelectedEntryId(responseEntry.entry._id); // Make sure this updates the state for highlighting the entry
      setIsEntrySaved(false);
      //console.log('setSelectedEntry: ', responseEntry.entry);
      //console.log('setSelectedEntryId: ', responseEntry.entry._id);
      const element0 = document.getElementById('Qi');
      //console.log('Qi exists: ', !!element0);
    } catch (error) {
      console.error("Error creating a new entry:", error);
      setIsCreatingEntry(false);
    }
  };

  
  const handleSaveEntry = async (text) => {
    const token = await getAccessTokenSilently();
    console.log("Save selectedEntry._id: ", selectedEntry._id);
    //console.log("Entry Saved");

    const updates = { response: text }; // Ensure this matches the server's expected format
    await saveEntry(selectedEntry._id, updates, token);
  
    setIsEntrySaved(true);
    
    const sortedEntries = await fetchEntries(searchQuery);
    setEntries(sortedEntries);
  };
  



// /**
//  * @function
//  * @name prepareAndProcessForm
//  * 
//  * 1. Initializes form state and default values.
//  * 2. Updates state based on user interactions.
//  * 3. Validates and submits the form.
//  * 
//  * @param {Object} initialFormState - Initial state of the form.
//  * @param {Function} submitHandler - Function for form submission.
//  * @returns {Object} - Current form state, errors, and handler functions.
//  */
     
// const prepareAndProcessForm = () => {
//   let formData = {};
//   const form = document.getElementById('gptForm');

//   if (form) {
//     Array.from(form.elements).forEach(element => {
//       if (element.labels && element.labels.length > 0) {
//         const questionId = element.id;
//         const questionText = element.labels[0].innerText;

//         if (element.tagName === 'TEXTAREA') {
//           // Standard textarea elements
//           formData[questionId] = {
//             questionText: questionText,
//             answer: element.value
//           };
//         } else if (element.classList.contains('ql-editor')) {
//           // Custom text editor handling
//           const editorContent = getTextFromEditor(element.id);
//           console.log('ql-editor found. element.id: ', element.id);
//           console.log('ql-editor found. content: ', editorContent);
//           formData[questionId] = {
//             questionText: questionText,
//             answer: editorContent
//           };
//         }
//       }
//     });
//   } else {
//     console.error("Form not found");
//   }

//   return formData;
// };



const handleEntrySelect = async (entryOrId) => {
  if (!isProcessing) {
    let entryData = entryOrId;

    // Check if entryOrId is just an ID (assuming IDs are strings and entry objects are not)
    if (typeof entryOrId === 'string') {
      entryData = allEntries.find(entry => entry._id === entryOrId);
      
      // If entry is not found in the already loaded entries, fetch it from the server
      if (!entryData) {
        entryData = await fetchEntryById(entryOrId);
      }
    }

    if (entryData) {
      setSelectedEntry(entryData);
      setEntryFormState({ ...entryFormState, richTextContent: entryData.content });
      setLastSavedContent(entryData.content);
      setIsEntrySaved(entryData.isSaved);
      setSelectedEntryId(entryData._id);

      console.log("setSelectedEntryId", selectedEntryId);
      //console.log("Set entry (EntrySelect): ", entryData);
      setIsCreatingEntry(false);

      if (entryData.guides) {
        setGuide1(entryData.guides.guide1 || null);
        setGuide2(entryData.guides.guide2 || null);
        setGuide3(entryData.guides.guide3 || null);
      } else {
        setGuide1(null);
        setGuide2(null);
        setGuide3(null);
      }
      console.log('activeTab: ', activeTab);

      if (activeTab > 0) {
        if (!entryData.guides) {
          setActiveTab(0); //moved to useEffect
        } else if (entryData.guides.guide3) {
          setActiveTab(3); //moved to useEffect
        } else if (entryData.guides.guide2) {
          setActiveTab(2); //moved to useEffect
        } else if (entryData.guides.guide1) {
          setActiveTab(1); //moved to useEffect
        } else {
          console.error("All guides are set. No null guide available.");
          // Handle the case when all guides are set (perhaps by showing an error or alert to the user)
        }
      } else {
        // setActiveTab(0);
      }
    }
  }
};

  

  useEffect(() => {
    if (guide1Waiting) {
      setShowFeedback(false);
      setActiveTab(1);
      setIsProcessing(true);
    }
  }, [guide1Waiting]); // This effect runs when 'guide1waiting' changes
  
  useEffect(() => {
    if (guide2Waiting) {
      setShowFeedback(false);
      setActiveTab(2);
      setIsProcessing(true);
    }
  }, [guide2Waiting]); // This effect runs when 'guide2waiting' changes
  
  useEffect(() => {
    if (guide3Waiting) {
      setShowFeedback(false);
      setActiveTab(3);
      setIsProcessing(true);
    }
  }, [guide3Waiting]); // This effect runs when 'guide3waiting' changes
  

/**
 * @function
 * @name handleSubmit
 * Function to integrate GPT-4 API for self-help content generation.
 *
 * @param {string} journalEntry - The user's journal entry text.
 * @param {Object} gpt4Config - Configuration options for the GPT-4 API call, such as API key, temperature, max tokens, etc.
 * @returns {Promise<Object>} - A promise that resolves to the generated self-help content.
 */

const handleSubmit = async (formData) => {
  try {
    console.log("Form data received in handleSubmit:", formData); // Log added
    const token = await getAccessTokenSilently();
    const serializedContent = JSON.stringify(formData);

let activeGuide = null;

if (guide1 === null) {
  setGuide1Waiting(true);
  activeGuide = 'guide1';
} else if (guide2 === null) {
  setGuide2Waiting(true);
  activeGuide = 'guide2';
} else if (guide3 === null) {
  setGuide3Waiting(true);
  activeGuide = 'guide3';
} else {
  console.error("All guides are set. No null guide available.");
  return; // Stop submission if all guides are filled
}

    console.log("Serialized content:", serializedContent); // Log added
    const jobResponse = await interactWithEntry(selectedEntry._id, serializedContent, token);
    console.log("Job response received:", jobResponse); // Log added
    const pollInterval = setInterval(async () => {
      try {
        const status = await checkJobStatus(jobResponse.jobId, token);
        if (status.complete || status.failed) {
          clearInterval(pollInterval); // Stop polling
          setIsProcessing(false);      // Ensure processing state is updated
          if (status.failed) {
            console.error(`Job failed with reason: ${status.failedReason}`);
          } else {
            // Pass data to handleJobCompletion to handle UI updates
            const jobCompletionData = {
              entryId: selectedEntry._id,
              serializedContent, // Original content for potential summary
              response: status.result.updateData, // GPT-generated feedback
              guide: activeGuide // Guide that was selected earlier
            };
            handleJobCompletion(jobCompletionData);
          }
        }
      } catch (error) {
        clearInterval(pollInterval);
        setIsProcessing(false); // Ensure processing state updates even on failure
        console.error('Error during job status check:', error);
      }
    }, 8000);
    
  } catch (error) {
    console.error("There was an error submitting the entry:", error);
  }
};

// Helper function to determine the guide identifier
const getGuideIdentifier = () => {
  if (guide1 === null) return 'guide1';
  if (guide2 === null) return 'guide2';
  if (guide3 === null) return 'guide3';
  console.error("All guides are set. No null guide available.");
  return null; // Handle the case when all guides are set
};

async function checkJobStatus(jobId, token) {
  try {
    const response = await fetch(`${API_URL}/job-status/${jobId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error(`Server responded with status: ${response.status}`);

    }

    const data = await response.json();
    console.log('Job Status for jobId:', jobId, data); // Log added
    // Prepare a log object to capture the status and error details
    const logStatus = {
      jobId: jobId,
      status: {
        complete: data.complete,
        failed: data.failed,
        active: data.active,
        delayed: data.delayed
      }
    };

    if (data.failed) {
      logStatus.error = {
        message: data.failedReason,
      };
    }

    // Log the job status along with error details if available
    //console.log('Job Status:', logStatus);

    return {
      complete: data.complete,
      failed: data.failed,
      active: data.active,
      delayed: data.delayed,
      result: data.result,
      failedReason: data.failedReason,
    };
  } catch (error) {
    console.error('Error checking job status:', error);
    // Include the error message in the thrown error for better traceability
    throw new Error(`Error checking job status for jobId ${jobId}: ${error.message}`);
  }
}

  function updateGuideContentState(updatedHtmlContent, activeGuide) {
    if (activeGuide === 'guide1') {
      setGuide1(updatedHtmlContent);
    } else if (activeGuide === 'guide2') {
      setGuide2(updatedHtmlContent);
    } else if (activeGuide === 'guide3') {
      setGuide3(updatedHtmlContent);
    } else {
      console.error("Invalid guide identifier:", activeGuide);
    }
  }


  async function handleJobCompletion(combinedData) {
    try {
      console.log('handleJobCompletion: combinedData: ', combinedData);
  
      if (!combinedData || typeof combinedData.entryId !== 'string' || typeof combinedData.response !== 'string' || typeof combinedData.guide !== 'string') {
        console.error("Invalid combined data structure:", combinedData);
        return;
      }
  
      // Update the guide content in the UI
      updateGuideContentState(combinedData.response, combinedData.guide);
  
      const { entryId, response, guide } = combinedData;
      const token = await getAccessTokenSilently();
  
      updateUIComponents(response, entryId, guide);
  
      // Clear the waiting state for the appropriate guide
      if (guide === 'guide1') {
        setGuide1Waiting(false);
      } else if (guide === 'guide2') {
        setGuide2Waiting(false);
      } else if (guide === 'guide3') {
        setGuide3Waiting(false);
      }
  
      // Update the database with the new feedback
      await updateDatabase(entryId, response, token);
  
      // Fetch sorted entries to update the UI
      const sortedEntries = await fetchEntries(searchQuery);
      setEntries(sortedEntries);
  
      // Update active tab and highlight the entry
      setActiveTab(guide === 'guide1' ? 1 : guide === 'guide2' ? 2 : 3);
      handleEntrySelect(entryId);
  
      // Create summaries based on the job results
      await createSummaryForEntry(entryId, combinedData.serializedContent, token);
      await createLifetimeSummary(user.sub, token);
    } catch (error) {
      console.error('Error handling job completion:', error);
    }
  }
  

async function updateUIComponents(response, entryId, guide) {


  // setActiveTab(guide.slice(-1)); // Sets the active tab based on guide number
  // setGuideWaiting(false, guide); // Assuming setGuideWaiting function handles the waiting state based on the guide


  // Update the entry form state
  setEntryFormState(prevState => ({
      ...prevState,
      gptFeedback: response,
      richTextContent: ''
  }));
  setShowFeedback(true);
  setIsCreatingEntry(false);

  // Update state for highlighting the entry
  setSelectedEntryId(entryId);
}

async function updateDatabase(entryId, response, token) {
  const updateData = { response: response }; // This is equivalent to { response }
  //console.log("Print updateData: ",updateData);
  const updateDBresponse = await fetch(`${API_URL}/entries/${entryId}/update`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json', // Correct for sending JSON
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(updateData) // Convert the object into a JSON string
  });

  if (updateDBresponse.ok) {
      const updatedEntry = await updateDBresponse.json();
      //console.log('Entry updated successfully:', updatedEntry);
  } else {
      const errorText = await updateDBresponse.text();
      console.error(`Failed to update entry: ${errorText}`);
  }
}


  // Add event listener to close sidebar when main-content is clicked
  useEffect(() => {
    const mainContent = document.querySelector('.main-content');
  
    if (!mainContent) {
      return;
    }
  
    const handleMainContentClick = () => {
      if (sidebarVisible) {
        setSidebarVisible(false); // This will close the sidebar
        setMenuActive(false);     // This will change the "X" back to the hamburger icon
      }
    };

    mainContent.addEventListener('click', handleMainContentClick);

    // Clean up the event listener when the component is unmounted or updated
    return () => {
      mainContent.removeEventListener('click', handleMainContentClick);
    };
  }, [sidebarVisible]); // Re-run the effect when sidebarVisible changes

  if (!isAuthenticated) {
    return (
      <>
        <SiteHeader menuActive={menuActive} toggleSidebar={toggleSidebar} />
        <div className="App">
          <LandingPage />
        </div>
      </>
    );
  }
  

return (
  <>
    <SiteHeader menuActive={menuActive} toggleSidebar={toggleSidebar} />
    <div className="App">
    <div className={`sidebar ${sidebarVisible ? 'visible' : ''}`}>
    {/* <UserTray userId={userId} /> */}
      <div className="header"><h2>Your Journey</h2></div>
      <EntryList
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        disabled={isProcessing}
        entries={entries}
        onSelect={handleEntrySelect}
        onNewEntry={handleNewEntry}
        selectedEntry={selectedEntry}
        setSelectedEntry={setSelectedEntry}
        selectedEntryId={selectedEntryId}
        setSelectedEntryId={setSelectedEntryId}
        setActiveTab={setActiveTab}
      />
    </div>

    <div className="main-content">
      <EntryForm
        entry={selectedEntry}
        onSave={saveEntry}
        searchQuery={searchQuery}
        disabled={isProcessing}
        selectedEntry={selectedEntry}
        selectedEntryId={selectedEntryId}
        handleDeleteEntry={handleDeleteEntry}
        handleSaveEntry={handleSaveEntry}
        entryFormState={entryFormState}
        setEntryFormState={setEntryFormState}
        guide1={guide1}
        guide2={guide2}
        guide3={guide3}
        guide1Waiting={guide1Waiting}
        guide2Waiting={guide2Waiting}
        guide3Waiting={guide3Waiting}
        tab1disabled={tab1disabled}
        tab2disabled={tab2disabled}
        tab3disabled={tab3disabled}
        isProcessing={isProcessing}
        isCreatingEntry={isCreatingEntry}
        handleSubmit={handleSubmit}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        showFeedback={showFeedback}
        setShowFeedback={setShowFeedback}
        lastSavedContent={lastSavedContent}
        setLastSavedContent={setLastSavedContent}
        lastSaved={lastSaved}
        setLastSaved={setLastSaved}
        saveStatus={saveStatus}
        setSaveStatus={setSaveStatus}
        
      />
    </div>
    </div>
  </>
);

}

export default App;
