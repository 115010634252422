import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const LandingPage = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = () => {
    loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signin',
        // ... any other params
      }
    });
    
  };

  const handleSignup = () => {
    loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup',
        // ... any other params
      }
    });
      };

  return (
    <div className="landing-container">
      <div className="left-column">
      <h1>Navigate Your Narrative</h1>
<h3>Discover the power of AI-guided reflective journaling</h3>
{/* <p className="important-text">Currently enrolling for our community of beta testers!</p> */}
<div class="button-container">
  <button onClick={handleSignup} class="landing_login_cta">Signup for Free ╶➢</button>
</div>
<p className="already-joined">Already joined? <span class="login-link" onClick={handleLogin}>Login here.</span></p>


{/* <div id="videoContainer" class="video-container">
  <video autoPlay muted loop id="myVideo">
    <source src="path_to_your_video.mp4" type="video/mp4" />
    Your browser does not support HTML5 video.
  </video>
  <div class="expand-icon"></div>
</div> */}



<img src="landing_img.png" alt="Lantern Image" class="landing-image"/>

{/* <p>Lantern AI is a powerful tool for personal exploration and self-actualization.</p> */}

{/* <p>Begin your journey toward purpose and fulfillment.</p>  */}

<ul class="feature-list">
  {/* <h2>What is Lantern AI?</h2> */}
  <h2>Lantern AI is a powerful tool for personal exploration and self-actualization, providing:</h2>
  <li><span class="feature-title">Guided Journaling:</span> AI-powered reflective prompts inspire self-observation and personal growth.</li>
  <li><span class="feature-title">Personalized Guidance:</span> Insights, life hacks and expert-informed methods illuminate your path forward.</li>
  <li><span class="feature-title">Seamless Timeline:</span> Revisit past reflections to integrate your discoveries and manifest your best self.</li>
</ul>
<div className="terms-privacy-container">
    <a rel="noreferrer" className="link-style"  href="/terms-of-service">Terms of Service</a>
    <span className="separator">|</span>
    <a rel="noreferrer" className="link-style"  href="/privacy-policy">Privacy Policy</a>
</div>

</div>

      </div>
  );
};

export default LandingPage;
