import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const getStageIcon = (currentChatStage) => {
  switch(currentChatStage) {
    case 1:
      return '✴'; // Green dot 🌱💡🟢
    case 2:
      return '1️⃣'; // Emoji for stage 2
    case 3:
      return '2️⃣'; // Emoji for stage 3
    case 4:
      return '3️⃣'; // Emoji for stage 4
    default:
      return '❕'; // Default case if currentChatStage is not 1, 2, 3, or 4
  }
};

function EntryList({ searchQuery, setSearchQuery, entries, onSelect, onNewEntry, setSelectedEntryId, selectedEntryId }) {
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [filteredEntries, setFilteredEntries] = useState([]);

  useEffect(() => {
    const lowercasedQuery = searchQuery.toLowerCase();
    const filtered = entries.filter(entry => 
      entry.content && entry.content.toLowerCase().includes(lowercasedQuery) // Ensure entry.content is defined
    );
    setFilteredEntries(filtered);
  }, [searchQuery, entries]);

  useEffect(() => {
    setFilteredEntries(entries.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
  }, [entries]);

  return (
    <div className="entry-list" >
    <div className="search-container">
      <button 
        onClick={() => setIsSearchVisible(!isSearchVisible)}
        className="toggle-search-button"
      >
        <i className="fas fa-search"></i> {/* Search icon from Font Awesome */}
      </button>


      {isSearchVisible && (
        <input
          type="text"
          placeholder="Search journal entries..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ width: '80%', margin: '10px 0' }}
        />
      )}
            </div>

      <button className="new-entry-button" onClick={onNewEntry}>New Entry</button>
      {filteredEntries.map((entry) => (
        <div
          key={entry._id}
          className={`entry ${entry._id === selectedEntryId ? 'selected' : ''}`}
          onClick={() => {
            setSelectedEntryId(entry._id);
            onSelect(entry._id);
          }}
        >
          <div className="entry-icon">{getStageIcon(entry.currentChatStage)}</div>
          <div className="entry-title">{entry.title}</div>
        </div>
      ))}
    </div>
  );
}

EntryList.propTypes = {
  searchQuery: PropTypes.string.isRequired,
  setSearchQuery: PropTypes.func.isRequired,
  entries: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  onNewEntry: PropTypes.func.isRequired,
  setSelectedEntryId: PropTypes.func.isRequired,
  selectedEntryId: PropTypes.string
};

export default EntryList;
