const API_URL = process.env.REACT_APP_API_URL || "/api";

/**
 * Adds a new entry to the journal.
 * @param {string} title - The title of the journal entry.
 * @param {string} content - The content of the journal entry.
 * @param {number} currentChatStage - The current stage of the chat.
 * @param {string|null} sessionId - The session ID, if available.
 * @returns {Promise<Object>} - The response from the server.
 */


export const addEntry = async (title, content, token) => {
  const requestBody = { title, content };
  // const requestBody = {
  //   content: Object.fromEntries(
  //     Object.entries(content).map(([questionId, { answer }]) => [questionId, answer])
  //   )
  // };
  
  //console.log('Creating new entry with requestBody: ', requestBody);

  const response = await fetch(`${API_URL}/entries`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}` // Include the authorization token in the request headers
    },
    body: JSON.stringify(requestBody)
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return await response.json();
};


export const interactWithEntry = async (entryId, content, token) => {
  const requestBody = { content };
  console.log('Sending content to interact with entryId:', entryId, requestBody); // Log added
  const response = await fetch(`${API_URL}/entries/${entryId}/interact`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(requestBody)
  });
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  const responseData = await response.json();
  console.log('Response from interactWithEntry:', responseData); // Log added
  return responseData;
};


export const createSummaryForEntry = async (entryId, content, token) => {
  const requestBody = { content };
  const response = await fetch(`${API_URL}/entries/${entryId}/create-summary`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(requestBody)
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return await response.json();
};


export const createLifetimeSummary = async (userId, token) => {

  const response = await fetch(`${API_URL}/entries/${userId}/create-lifetime-summary`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    // body: JSON.stringify({
    //   userId: user.sub, // Auth0's user identifier
    // })

  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return await response.json();
};

// TO BE ADDED TO AddEntry FOR STREAMING
// const handleStreamingResponse = (streamingData) => {
//   // Update the state or DOM with the streaming data
//   // ...
// };

/**
 * Retrieves all journal entries.
 * ...
 */

export const getAllEntries = async (token) => {
  // Ensure API_URL is defined, for example, as an environment variable
//console.log("Fetching entries from: ", API_URL + "/entries");
  try {
    const response = await fetch(`${API_URL}/entries`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });

    if (!response.ok) {
      // If the response is not 2xx, throw an error
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    // Log the error or handle it as necessary
    console.error("Error fetching entries:", error);
    throw error; // Re-throwing the error is important if you want calling code to handle it
  }
};

/**
 * Retrieves a single journal entry by ID.
 * ...
 */

export const getEntry = async (id) => {
  const response = await fetch(`${API_URL}/entries/${id}`);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return await response.json();
}

/**
 * Updates an existing journal entry by ID.
 * ...
 */

export const saveEntry = async (id, updates, token) => {
  const response = await fetch(`${API_URL}/entries/${id}/saveEntry`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(updates) // Make sure to stringify your payload
  });

  if (!response.ok) {
    const errorResponse = await response.text();
    throw new Error(`Network response was not ok: ${errorResponse}`);
  }

  return await response.json();
};



/**
 * Deletes an existing journal entry by ID.
 * ...
 */

export const deleteEntry = async (id, token) => {
  const response = await fetch(`${API_URL}/entries/${id}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return await response.json();
}

// api.js
export const getUserProfile = async (userId, token) => {
  const response = await fetch(`${API_URL}/user-profile/${userId}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  if (!response.ok) throw new Error('Failed to fetch user profile');
  return await response.json();
};

