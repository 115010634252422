import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate instead of useHistory


const PrivacyPolicy = () => {
  // const [accepted, setAccepted] = useState(false);

  // const handleAcceptance = () => {
  //   setAccepted(!accepted);
  // };
  let navigate = useNavigate(); // Initialize useNavigate hook

  // Function to navigate to the previous page in history
  const handleGoBack = () => {
    navigate(-1); // Navigate to the previous page in history
  };

  // const handleSubmit = () => {
  //   if (accepted) {
  //     // Implement actions upon acceptance, e.g., storing the acceptance, navigating away, etc.
  //   } else {
  //     alert('Please accept the Privacy Policy to proceed.');
  //   }
  // };

  return (
    <div className="privacy-policy-container">
       <button onClick={handleGoBack} className="back-button">&#x2190;</button> {/* Thick left pointing arrow */}
      <div className='h1-container'> <h1>🔒<br></br>Privacy and Trust at Lantern AI</h1></div>
      <p>Your journaling journey is deeply personal, and we're committed to ensuring your experience with Lantern AI is both secure and private.</p>
      <section>
        <h2>Your Journal, Your Rules</h2>
        <p>Your entries are stored in your own designated storage, giving you full control. For enhanced security, consider enabling advanced protection features like two-factor authentication.</p>
      </section>
      <section>
        <h2>Enhanced Security with OpenAI</h2>
        <p>We partner with OpenAI to provide personalized insights. Your entries are transmitted securely using industry-standard encryption protocols, ensuring your data remains safe and private.</p>
      </section>
      <section>
        <h2>Our Commitment to Your Privacy</h2>
        <p>Lantern AI does not store your data beyond what is necessary for operational integrity. OpenAI retains data for a limited period to monitor misuse, adhering strictly to SOC 2 compliance, the gold standard in data security and privacy.</p>
      </section>
      <section>
        <h2>Staying Informed</h2>
        <p>Your trust is paramount. We commit to transparent communication and will notify you of any changes to this policy, ensuring you're always informed and in control.</p>
      </section>

        <p>By using Lantern AI, you acknowledge and agree to the terms outlined in this Privacy Policy.</p>
        {/* <input
          type="checkbox"
          id="acceptPolicy"
          checked={accepted}
          onChange={handleAcceptance}
        />
        <label htmlFor="acceptPolicy">I accept the terms of the Privacy Policy.</label>

      <button
        onClick={handleSubmit}
        disabled={!accepted}
        className={accepted ? 'button-enabled' : 'button-disabled'}
      >
        Submit
      </button> */}
    </div>
  );
};

export default PrivacyPolicy;
