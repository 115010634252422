import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AuthCallback from './components/AuthCallback';
import ErrorPage from './components/ErrorPage';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import reportWebVitals from './reportWebVitals';
import LandingPage from './components/LandingPage'; 
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
// import 'bootstrap/dist/css/bootstrap.min.css';

{/* Your Auth0 domain and client ID */}
const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const auth0Audience = process.env.REACT_APP_AUTH0_API_ID;

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Auth0Provider
    domain={auth0Domain}
    clientId={auth0ClientId}
    authorizationParams={{
      redirect_uri: process.env.NODE_ENV === 'production' 
          ? "https://journeywithlantern.com/callback"
          : "http://localhost:3000/callback",
      audience: auth0Audience,
    }}
  >
    <BrowserRouter>
      <Routes>
        <Route path="/welcome" element={<LandingPage />} />
        <Route path="/" element={<App />} />
        <Route path="/callback" element={<AuthCallback />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} /> {/* Privacy Policy route */}
        <Route path="/terms-of-service" element={<TermsOfService />} /> {/* Privacy Policy route */}
        {/* Add other routes here */}
      </Routes>
    </BrowserRouter>
  </Auth0Provider>
);

reportWebVitals();
