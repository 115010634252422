import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL || "/api";

const AuthCallback = () => {
  const { user, isAuthenticated, isLoading, error, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const handleAuthCallback = async () => {
      if (error) {
        console.error('Error during authentication:', error);
        setErrorMessage(error.message); // Set error message to state
        navigate('/error', { state: { message: error.message } });
        return;
      }

      if (!isLoading && isAuthenticated) {
        try {
          console.log(user);
          const accessToken = await getAccessTokenSilently();
          // Update user profile
      
      
          const response = await fetch(`${API_URL}/user-profiles/update`, { // Corrected template literal usage
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              userId: user.sub, // Auth0's user identifier
              name: user.name,
              email: user.email
            })
          });

          if (!response.ok) {
            throw new Error('Failed to update user profile');
          }

          const profileData = await response.json();
          console.log('Profile updated successfully:', profileData);
          navigate('/'); // Navigate to home page or dashboard after successful login and profile update
        } catch (updateError) {
          console.error('Error updating user profile:', updateError);
          setErrorMessage(updateError.message);
          navigate('/error', { state: { message: updateError.message } }); // Navigate to error page with error details
        }
      }
    };

    handleAuthCallback();
  }, [user, isAuthenticated, isLoading, error, getAccessTokenSilently, navigate]);

  return isLoading ? <div>Loading...</div> : errorMessage ? <div>Error: {errorMessage}</div> : null;
};

export default AuthCallback;
