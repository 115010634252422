import { useLocation } from 'react-router-dom';

const ErrorPage = () => {
  const location = useLocation();
  const message = location.state?.message || 'An unknown error occurred.';
  
  return (
    <div>
      <h1>An Error Occurred</h1>
      <p>{message}</p>
      {/* Add additional error details or actions here */}
    </div>
  );
};

export default ErrorPage;